  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  /* scrollbar designing */
  ::-webkit-scrollbar {
    width: .4em;
    height: .5em;
  }

  ::-webkit-scrollbar-track {
    background-color: #201e1938;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #000080;
    border-radius: 10px;
  }


  /* Common button Classes */
  .common_content {
    padding: 1rem;
    height: 75vh;
    overflow: auto;
    width: 72%;
    /* overflow: hidden; */
    top: 20%;
    left: 25%;
    /* border: 2px solid red; */
    position: absolute;
  }

  .common_card {
    width: 100%;
    background: #fff;
    box-shadow: 0px 5px 25px #52575D1A;
    border-radius: 10px;
    padding: 1.5rem 1.5rem;
  }

  .common_card2 {
    max-height: 75vh;
    overflow: auto;
  }

  .common_btn {
    background: linear-gradient(to right, #000080, #0E4C92);
    height: 50px;
    color: #fff;
    border: none;
    border-radius: 10px;
  }

  .common_btn_color {
    background: linear-gradient(to right, #000080, #0E4C92) !important;
  }

  .common_btn:hover {
    color: #fff !important;
  }

  .main_shadow {
    position: fixed;
    min-height: calc(100vh);
    background: #FEFEFE;
    background-image: url('./assets/grayshadow.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .very_small_font {
    font: 500 0.8rem 'Poppins', sans-serif !important;
    /* color: #3D3D3D; */
  }

  .extra_small_font {
    font: 600 1rem 'Poppins', sans-serif;
    color: #3D3D3D;
  }

  .small_font {
    font: bold 1rem 'Poppins', sans-serif;
    color: #3D3D3D;
  }

  .medium_font {
    font: bold 1.2rem 'Poppins', sans-serif;
    color: #3D3D3D;
  }

  .big_font {
    font: bold 1.5rem 'Poppins', sans-serif;
    color: #3D3D3D;
  }

  .extra_margin {
    margin: 1rem 0;
  }

  /* Auth section styling */

  .left_auth_section {
    height: 100vh;
    display: flex;
    align-items: flex-end;
  }

  .blueshadow_image {
    position: relative;
  }

  .truck_image_div {
    top: 0%;
    position: absolute;
  }

  .right_auth_col {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .right_auth_section {
    /* border: 1px solid red; */
    /* min-height: 100vh; */
    width: 80%;
    background: #fff;
    box-shadow: 0px 0px 30px #A6A6A633;
    border-radius: 10px;
    text-align: center;
    padding: 3rem 5rem;
  }

  .input_feilds {
    box-shadow: 0px 3px 5px #0000800D;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    border: 1px solid #00008033 !important;
  }

  .input_width {
    width: 50%;
  }

  .input_feilds:focus {
    border: 1px solid #00008033 !important;
  }

  .login_btn_div {
    width: 80%;
    margin: auto;
  }

  /* Dashboard section styling */
  .width_manage {
    width: 400px;
  }

  .typo_space>.ant-typography {
    padding: 0 0.6rem;
    display: inline-block;
    cursor: pointer;
  }

  .chart_div {
    margin-top: 2rem;
    padding: 1rem;
    background: #fff;
    box-shadow: 0px 5px 25px #52575D1A;
    border-radius: 10px;
  }

  .main_content {
    /* border: 1px solid red;
    width: 400px;
    overflow: auto; */
  }

  /* Userlist section styling */
  .del_btn {
    border: none;
    background: #FF5353;
    padding: 0 2rem;
    height: 30px;
  }

  .del_btn_back {
    background: linear-gradient(to right, #000080, #0E4C92);
  }

  .del_btn:hover {
    color: #fff !important;
  }

  :where(.css-dev-only-do-not-override-diro6f).ant-table-wrapper .ant-table-thead>tr>th,
  :where(.css-dev-only-do-not-override-diro6f).ant-table-wrapper .ant-table-thead>tr>td {
    background: #EBEBFF;
    color: #000080;
  }

  :where(.css-dev-only-do-not-override-diro6f).ant-table-wrapper .ant-table-tbody>tr>td {
    border-bottom: none;
  }

  :where(.css-dev-only-do-not-override-diro6f).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #EBEBFF;
  }

  :where(.css-dev-only-do-not-override-diro6f).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #EBEBFF;
  }

  .ant-switch-checked .ant-switch-inner {
    background: #000080;
  }

  .ant-pagination .ant-pagination-item-active a {
    background: #000080;
    color: #fff !important;
    border-radius: 50%;
  }

  .ant-pagination .ant-pagination-item-active {
    background: transparent;
    border: none;
  }

  .ant-modal .ant-modal-content {
    width: 70%;
    margin: auto;
  }

  .ant-table-tbody {
    background: #fff;
  }

  /* custom notification styling */
  .common_card_width {
    width: 60%;
  }

  /* verification screen styling */
  .vi__character {
    height: 60px !important;
    border: none !important;
    box-shadow: 0px 5px 15px #0000000D;
    border-radius: 5px;
    background: #fff !important;
    /* margin: 0 5px; */
    border: 1px solid #00008033 !important;
  }

  .vi__character--selected {
    outline: 2px solid #000080;
  }

  .vi__wrapper {
    width: 100% !important;
  }

  .vi__container {
    gap: 20px !important;
    width: 100% !important;
  }

  /* profile styling */
  .profile_div {
    width: 70%;
    margin: auto;
  }

  /* landing page styling */
  .icon_div {
    /* background: #000080;
    padding: 0.2rem;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center; */
  }

  .main_div {
    /* display: flex;
    align-items: center; */
    /* background: #EEEEEE 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px; */
    /* padding: 0.5rem 0.6rem; */
  }

  .add_icon_div {
    background: #fff;
    box-shadow: 0px 5px 10px #0000001A;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ck.ck-editor__main>.ck-editor__editable {
    height: 250;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    width: 100%;
    height: 130px;
    border: 2px dashed #000080;
    background: #fff;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select:hover {
    border: 2px dashed #000080 !important;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
    width: 100%;
    height: 130px;
    border: 2px dashed #000080;
  }

  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
    border: none;
  }

  .ant-upload-list-item-name {
    color: #000080 !important;
    display: none;
  }

  .ant-tooltip .ant-tooltip-inner {
    display: none;
  }

  .anticon-eye svg {
    display: none;
  }

  /* .anticon-delete {
display: none !important;
} */
  /* .ant-upload-list-item-actions {
display: none !important;
} */
  .ant-input::placeholder {
    color: #000;
  }

  @media screen and (min-width:1600px) {
    .extra_margin {
      margin: 3.5rem 0;
    }

    .right_auth_section {
      padding: 5rem 4rem;
      width: max-content;
    }

    .right_auth_col {
      justify-content: center;
    }

    .input_feilds {
      height: 60px;
    }

    .common_btn {
      height: 60px;
    }

    .very_small_font {
      font: 600 1.1rem 'Poppins', sans-serif;
    }

    .medium_font {
      font: 600 1.5rem 'Poppins', sans-serif;
    }

    .vi__character {
      height: 100px !important;
    }

    .vi__container {
      /* gap: 50px !important; */
      width: 100% !important;
    }

    .del_btn {
      height: 40px;
    }
  }

  @media screen and (max-width:991px) {
    .width_manage{
      width: 100%;
    }
    .profile_div {
      width: 100%;
      margin: auto;
    }

    .right_auth_section {
      margin: auto;
    }

    .common_content {
      width: 100%;
      /* top: 15%; */
      left: 0%;
    }

    .common_card2 {
      height: 100%;
    }

    .common_card_width {
      width: 100%;
    }
  }

  @media screen and (max-width:550px) {
    .width_manage{
      width: 450px;
    }
    .ant-modal .ant-modal-content {
      width: 100%;
      margin: auto;
    }

    .right_auth_section {
      min-height: 100vh;
      width: 100%;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .input_width {
      width: 100%;
    }

    /* .common_content {
      top: 20%;
    } */
  }