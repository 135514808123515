/* welcome screen styling*/
.welcome_screen {
    background-color: #E3E3E3;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome_img_width {
    width: 25%;
}

/* sidebar styling */
/* .closeIcon {
    display: none;
} */
.main_list_div>a {
    text-decoration: none;
    color: #000;
}

.sidebar_div {
    top: 0%;
    position: fixed;
    background: #fff;
    box-shadow: 5px 0px 15px #0000001A;
    min-width: 22vw;
    max-width: 250px;
    height: 100vh;
    overflow: auto;
}

.list_div {
    background: #EBEBFF;
    padding: 0.5rem 0 0.5rem 1rem;
}

.list_div_dark {
    background: #0000802a;
    padding: 0.5rem 0 0.5rem 1rem;
}

.ant-drawer-content-wrapper {
    width: 250px !important;
}

.ant-drawer-body {
    padding: 0 !important;
}

/* navbar styling */
.nav_div {
    position: relative;
    width: 100%;
    background: #fff;
    box-shadow: 0px 5px 10px #0000001A;
    padding: 1.7rem 0;
}

.nav_content {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* text-align: end; */
}

.search_div {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border: 1px solid #00008033;
    border-radius: 30px;
}

.search_div input {
    width: 200px !important;
    border: none;
    outline: none;
}

.user_account_div {
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    border: 1px solid #00008033;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.user_account_div select {
    border: none;
    outline: none;
    background: transparent;
}

.dropdown-menu {
    background: #DFDFFF !important;
    min-width: 0 !important;
    padding: 0 0.5rem !important;
    margin-top: 1rem !important;
}


@media screen and (min-width:1600px) {
    .nav_div {
        padding: 2.5rem 0;
    }
}

@media screen and (max-width:991px) {
    .sidebar_div {
        display: none;
    }

    .user_account_div {
        display: none;
    }

    /* .closeIcon {
        display: block;
    } */
}

@media screen and (max-width:550px) {
    .welcome_img_width {
        width: 50%;
    }
}